<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark pl-2">{{ $t('SHOP_ITEM.PERIOD.INFO') }}</span>
      </h3>
      <div class="card-toolbar">

      </div>
    </div>

    <div class="card-body pt-0 pb-3">

      <b-table
        id="periods-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="period_items"
        head-variant="light"
        ref="periods-table"
      >

      <template #cell(selected)="row">
        <v-checkbox
          v-model="row.item.selected"
          class="my-0 py-0"
          color="#3699FF"
          hide-details
          :ripple="false"
          @change="selection_changed(row.item.selected, row.item.id)"
        ></v-checkbox>
      </template>

    </b-table>

    </div>

  </div>

</template>
<style lang="scss" scoped>

:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

</style>
<script>
import axios from 'axios';

import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: "shop_item-periods-table",
  props: ['period_ids','shop_item_id'],
  emits: ['changed'],
  components: {

  },
  mixins: [ toasts ],
  watch: {
    shop_item_id: {
      deep: true,
      handler(val) {

        if (this.period_ids) {
          this.local_period_ids = [ ...this.period_ids ];

          this.refresh_key++;
        }
      }
    },
  },
  computed: {
    ...mapGetters(['periods','currentCompanyId']),
    period_items() {

      // workaround to force the computed property to recalculate
      this.refresh_key;

      if (!this.periods) {
        return [];
      }

      const periods = [ ...this.periods ];

      for (const p of periods) {
        p.selected = false;
      }

      for (const selected_id of this.local_period_ids) {
        const item = this.periods.find(i => i.id === selected_id);
        if (!item) {
          console.error('unable to find period with id: ' + selected_id);
          continue;
        }
        item.selected = true;
      }

      let fin_periods = periods.sort((a, b) => {
        return a.from < b.from ? 1 : -1;
      });

      return fin_periods;
    }
  },
  methods: {

    selection_changed(selected, period_id) {

      if (selected) {
        this.local_period_ids.push(period_id)
      }
      else {
        this.local_period_ids = this.local_period_ids.filter(item => item !== period_id)
      }
      console.log('local_period_ids', this.local_period_ids)
      this.$emit('changed', this.local_period_ids);

    },

  },
  mounted() {

    if (this.period_ids) {
      this.local_period_ids = [ ...this.period_ids ];

      this.refresh_key++;
    }

  },
  data() {
    return {

      refresh_key: 0,

      local_period_ids: [],

      fields: [

        {
          visible: true,
          key: 'selected',
          label: '',
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          visible: true,
          key: 'name',
          label: this.$t('SHOP_ITEM.PERIOD.NAME'),
        },

        {
          visible: true,
          key: 'from',
          label: this.$t('SHOP_ITEM.PERIOD.FROM'),
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'to',
          label: this.$t('SHOP_ITEM.PERIOD.TO')
        },
      ]
    };
  }
};
</script>

